import React from 'react'
import lk from "../assets/images/lk.svg"
import ig from "../assets/images/ig.svg"
import fb from "../assets/images/fb.svg"

export default function Footer() {
  return (
    <div className='box-footer bg-89 d-flex flex-column align-items-center width-100'>
      <p className='DMSans-400 c-ff s-1418'>© 2023 CRANIUM INDONESIA, ALL RIGHTS RESERVED.</p>
      <div className='d-flex justify-content-center'>
        <img src={ig} alt='' />
        <img src={lk} alt='' />
        <img src={fb} alt='' />
      </div>
    </div>
  )
}
