import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import banner from "../../assets/case/Banner-New.png";
import { getClientId } from "../../services/api";
import ScrollDown from "../ScrollDown";
import LoadingComp from "../Loading/LoadingComp";

export default function Banner() {
  const { id } = useParams();
  const [clientDetail, setClientDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  async function getClientIdData() {
    try {
      const responseDetail = await getClientId(id).then(
        (result) => result.data.data
      );
      setClientDetail(responseDetail);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getClientIdData();
  }, []);
  return (
    <div className="banner relative d-flex align-items-center justify-content-center mb-200">
      <ScrollDown />
      <img
        src={banner}
        className="img-banner img-fluid img-cover width-100"
        alt="img-banner-study"
        data-aos="zoom-in"
        data-aos-duration="1500"
      />
      <>
        {loading ? (
          <LoadingComp statusLoading={setLoading} color="#fff" />
        ) : (
          <div
            className="banner-title relative z-2"
            data-aos="zoom-in"
            data-aos-duration="2500"
          >
            <h1 className="Arlon c-ff s-96">{clientDetail.name}</h1>
          </div>
        )}
      </>
    </div>
  );
}
