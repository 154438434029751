import React, { useState, useEffect } from "react";
import Iframe from "react-iframe";
import Aos from "aos";
import "aos/dist/aos.css";
import mail from "../../assets/images/mail.png";
import locationImg from "../../assets/images/location.png";
import call from "../../assets/images/call.png";
import { getLocation, getLocationType } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function OfficeLocation() {
  const [id, setId] = useState(1);
  const [loading, setLoading] = useState(true);
  const [locationType, setLocationType] = useState();
  const [location, setLocation] = useState([]);

  // const types = data.data;
  async function getLocationTypeData() {
    try {
      const responseType = await getLocationType().then(
        (result) => result.data.data
      );
      setLocationType(responseType);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getLocationData() {
    try {
      const responseLocation = await getLocation(id).then(
        (result) => result.data.data
      );
      setLocation(responseLocation);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    Aos.init();
    getLocationTypeData();
    getLocationData();
  }, []);

  useEffect(() => {
    async function updateLocation() {
      try {
        const locationResponse = await getLocation(id);
        setLocation(locationResponse.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    updateLocation();
  }, [id]);

  return (
    <div
      className="d-flex justify-content-center mb-200 width-100"
      id="officeLocation"
    >
      <div className="office-location" id="location">
        <h3
          className="Arlon uppercase mb-100"
          data-aos="fade-up-right"
          data-aos-duration="1500"
        >
          OFFICE LOCATIONS
        </h3>
        {loading ? (
          <LoadingComp statusLoading={setLoading} color="#b89848" />
        ) : (
          <>
            <div className="d-flex justify-content-center">
              <div
                className="d-flex switch bg-ef mb-100"
                data-aos="fade-up-left"
                data-aos-duration="1500"
              >
                {locationType.map((typeView) => (
                  <p
                    id={typeView.id}
                    key={typeView.id}
                    className={`DMSans-400 text-nowrap s-2431 pointer trans ${
                      id === typeView.id ? "c-ff bg-89" : " c-82"
                    }`}
                    onClick={() => setId(typeView.id)}
                  >
                    {typeView.name}
                  </p>
                ))}
              </div>
            </div>
            {loading ? (
              <LoadingComp statusLoading={setLoading} color="#b89848" />
            ) : (
              <div className="row">
                <div
                  className="address-location col-lg-6 d-flex flex-column text-start mb-50"
                  data-aos="fade-up-right"
                  data-aos-duration="1500"
                >
                  <h6 className="c-00 DMSans-700 s-3242 mb-50">
                    {location.typeName}
                  </h6>
                  <div className="d-flex gap-4 align-items-center mb-50">
                    <img src={locationImg} alt="" className="icon" />
                    <p className="c-00 DMSans-400 s-2431">{location.address}</p>
                  </div>
                  <div className="d-flex gap-4 align-items-center mb-50">
                    <img src={call} alt="" className="icon" />
                    <p className="c-00 DMSans-400 s-2431">{location.phone}</p>
                  </div>
                  <div className="d-flex gap-4 align-items-center">
                    <img src={mail} alt="" className="icon" />
                    <p className="c-00 DMSans-400 s-2431">{location.email}</p>
                  </div>
                </div>
                <div className="col-lg-6 maps p-3 mb-50">
                  <Iframe
                    url={`https://maps.google.com/maps?q=${location.name}${location.latitude},${location.longitude}&output=embed`}
                    allowFullScreen=""
                    loading="lazy"
                    position="relative"
                    //  referrerPolicy="no-referrer-when-downgrade"
                    // data-aos="zoom-in"
                    // data-aos-duration="1000"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
