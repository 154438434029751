import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import digital_wave from "../../assets/homepage/digital_wave.png";
import { getLocation } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";
export default function DigitalWave() {
  const [loading, setLoading] = useState(true);
  const [location, setLocation] = useState([]);
  async function getLocationData() {
    try {
      const responseLocation = await getLocation(1).then(
        (result) => result.data.data
      );
      setLocation(responseLocation);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getLocationData();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div
          className="digital-wave relative d-flex flex-column justify-content-center align-items-center width-100"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <img
            src={digital_wave}
            className="bg-wave img-cover img-fluid width-100 "
            alt="img-wave"
          />
          <h6
            className="DMSans-700 c-ff s-6483"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Ready to ride digital wave with us?
          </h6>

          <Link to={`tel:${location.phone}`}>
            <div
              className="box-contact relative"
              data-aos="zoom-in-left"
              data-aos-duration="1500"
            >
              <div className="contact-shadow"></div>
              <button className="c-ff bg-89 DMSans-700 s-1823 br-60">
                Contact Us
              </button>
            </div>
          </Link>
        </div>
      )}
    </>
  );
}
