import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "moment";
import banner from "../../assets/articles/Rectangle.png";
import art1 from "../../assets/articles/art1.png";
import LoadingComp from "../Loading/LoadingComp";
import Aos from "aos";
import "aos/dist/aos.css";
import { getArticles } from "../../services/api";

export default function TopArticle() {
  const [loading, setLoading] = useState(true);
  const [listArticles, setListArticles] = useState([]);
  async function getArticle() {
    try {
      const responseArticles = await getArticles().then(
        (result) => result.data.data.content
      );
      let listData = [];
      for (let index = 1; index < 5; index++) {
        listData.push(responseArticles[index]);
      }
      setListArticles(listData);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getArticle();
  }, []);
  return (
    <div className="d-flex justify-content-center align-items-center">
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div className="width-100">
          <p className="DMSans-700 s-3242 c-00 mt-50 mb-20 text-start">
            Top Articles
          </p>
          <div className="row top-article box-article mb-100">
            {listArticles.map((trendMain, i) => {
              return (
                i === 0 && (
                  <div
                    className="col-lg-8 col-md-12 banner"
                    key={trendMain.id}
                    data-aos="fade-up-right"
                    data-aos-duration="1500"
                  >
                    <img
                      src={banner}
                      className="img-top-article img-fluid img-cover"
                      alt="img-top-article"
                    />
                    <div className="banner-text z-2 br-20 d-flex flex-column justify-content-between width-100">
                      <div className="col-12 d-flex justify-content-between">
                        <p className="DMSans-700 c-00 s-1823 bg-255 br-5 p-10">
                          News
                        </p>
                        <p className="DMSans-400 c-00 d-none d-md-flex s-14 bg-255 br-5 p-10">
                          {Moment(trendMain.date).format("DD MMMM YYYY")}
                        </p>
                      </div>
                      <div className="col-12 d-lg-flex d-none flex-column text-start bg-255 br-20 p-15 width-70">
                        <h6 className="DMSans-700 s-2431 mb-10">
                          {trendMain.title.substring(0, 45)}
                        </h6>
                        <Link
                          to={trendMain.link}
                          target="_blank"
                          className="no"
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: trendMain.summary.substring(0, 250),
                            }}
                          ></div>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
            <div
              className="col-lg-4 col-md-12 list-top-articles"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <div className="d-flex flex-column">
                <div className="d-none">
                  <div className="nav-list col-md-12 col-8 d-flex justify-content-between button gap-3">
                    <button className="btn btn-primary width-50">News</button>
                    <button className="btn btn-primary width-50">Event</button>
                  </div>
                </div>
                {listArticles.map((articleTrend, i) => {
                  return (
                    i === 0 && (
                      <Link
                        to={articleTrend.link}
                        target="_blank"
                        className="no"
                        key={articleTrend.id}
                      >
                        <div className="d-flex d-lg-none text-start flex-column col-12 mt-30">
                          <h6 className="DMSans-700 s-2431 mb-10">
                            {articleTrend.title}
                          </h6>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: articleTrend.summary,
                            }}
                          ></div>
                        </div>
                      </Link>
                    )
                  );
                })}

                <div className="scroll-article d-flex flex-column">
                  {listArticles.map((item, i) => (
                    <Link to={item.link} target="_blank" className="no">
                      <div className="d-flex col-12 mb-20" key={i}>
                        <img
                          src={art1}
                          className="img-top-list img-fluid img-cover br-10"
                          alt="img-top-list"
                        />
                        <div className="d-flex flex-column justify-content-between content">
                          <p className="DMSans-700 s-1823 c-00 text-start">
                            {item.title.substring(0, 15)}...
                          </p>
                          <div
                            className="list-p"
                            dangerouslySetInnerHTML={{
                              __html: item.summary.substring(0, 50),
                            }}
                          ></div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
