import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import mac from "../../assets/homepage/macbook.png";
import bc_red from "../../assets/images/bc_red.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTestimonies, getClient, getClientSlider } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function OurClients() {
  const [testimonies, setTestimonies] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientSlider, setClientSider] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getTestimoniesData() {
    try {
      const responseTestimonies = await getTestimonies().then(
        (result) => result.data.data
      );
      setTestimonies(responseTestimonies);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getClienstData() {
    try {
      const responseClients = await getClient().then(
        (result) => result.data.data
      );
      setClients(responseClients);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getClienstSliderData() {
    try {
      const responseSlider = await getClientSlider().then(
        (result) => result.data.data
      );
      setClientSider(responseSlider);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getTestimoniesData();
    getClienstData();
    getClienstSliderData();
  }, []);

  var clientBannerStudy = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
  };

  var clientList = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
  };

  var clientSays = {
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: false,
    // centerPadding: "60px",
    autoplay: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    // prevArrow: <button><img src={left} alt='left-arrow' className='img-fluid' /></button> ,
    // nextArrow: <img src={right} alt='right-arrow' className='img-fluid'/>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          // centerPadding: '60px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <div
      className="clients-box d-flex flex-wrap justify-content-center width-100"
      id="clients"
    >
      <h4
        className="c-89 DMSans-400 width-100"
        data-aos="fade-right"
        data-aos-duration="1500"
      >
        OUR CLIENTS
      </h4>
      <h3
        className="Arlon uppercase width-100"
        data-aos="fade-left"
        data-aos-duration="1500"
      >
        <span className="c-89">Fuelling success</span> for brands and
        enterprises
        <br /> across <span className="c-89">all sectors</span>
      </h3>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div
          className="container-inner mb-150"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <Slider {...clientBannerStudy}>
            {clients.map((clientData) => (
              <div className="client-card d-flex flex-wrap" key={clientData.id}>
                <div className="client-inner relative d-flex align-items-end width-100">
                  {/* {clientData.background.map((clientBack) => {
                  return (
                    clientBack.type === 1 && (
                      <img
                        src={clientBack.content}
                        className="img-client-bg img-cover width-100"
                        alt="img-client-bg"
                      />
                    )
                  );
                })} */}
                  <img
                    src={clientData.background}
                    className="img-client-bg img-cover width-100"
                    alt="img-client-bg"
                  />
                  <div className="client-content d-flex align-items-end relative width-100 z-2">
                    <div className="client-desc mb-40 ms-40">
                      <div className="d-flex flex-column text-start">
                        <div className="d-flex justify-content-start width-100">
                          <img
                            src={clientData.logo}
                            className="img-client-brand img-contain img-fluid"
                            alt="img-client-brand"
                          />
                        </div>
                        <h6 className="c-ff DMSans-700 s-4052">
                          {clientData.name}
                        </h6>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: clientData.shortDescription,
                          }}
                        ></div>
                        <Link
                          to={`/case-study/${clientData.id}`}
                          className="no c-ff DMSans-400 s-2026 mt-40"
                        >
                          View Case study &nbsp;{">"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="client-desc-mobile p-20 width-100">
                  <div className="d-flex flex-column text-start">
                    <div className="d-flex justify-content-start width-100">
                      <img
                        src={clientData.logo}
                        className="img-client-brand img-contain img-fluid"
                        alt="img-client-brand"
                      />
                    </div>
                    <h6 className="c-00 DMSans-700">{clientData.name}</h6>
                    <div
                      className=""
                      dangerouslySetInnerHTML={{
                        __html: clientData.shortDescription,
                      }}
                    ></div>
                    <Link
                      to={`/case-study/${clientData.id}`}
                      className="no c-00 DMSans-400 s-2026 mt-40"
                    >
                      View Case study &nbsp;{">"}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div className="d-flex justify-content-center width-100">
        {loading ? (
          <LoadingComp statusLoading={setLoading} color="#b89848" />
        ) : (
          <div
            className="client-list width-100"
            data-aos="fade-down"
            data-aos-duration="1500"
          >
            <Slider {...clientList}>
              {clientSlider.map((clientSlider) => (
                <div className="client-brand" key={clientSlider.id}>
                  <img
                    src={clientSlider.image}
                    className="img-fluid img-contain"
                    alt="client-list"
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-center width-100">
        <div className="client-testimony">
          <h4
            className="c-00 DMSans-700 s-3647 title"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            What our Clients say
          </h4>
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#b89848" />
          ) : (
            <div className="width-100 my-50">
              <Slider {...clientSays}>
                {testimonies.map((item) => (
                  <div className="p-3" key={item.id}>
                    <div
                      className="card d-flex flex-column align-items-start"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <div className="d-flex small-icon align-items-center">
                        <img
                          src={item.clientLogo}
                          alt={item.clientLogo}
                          className="img-fluid img-cover"
                        />
                        <p className="c-00 DMSans-700 s-2431 ms-3">
                          {item.clientName}
                        </p>
                      </div>
                      <h6 className="c-00 DMSans-700 s-2026 sub-title">
                        {item.title}
                      </h6>
                      <div
                        className="testimony-desc text-start"
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
