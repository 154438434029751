import { React } from "react";
import "../../styles/About.css";
import Header from "../../components/HeaderHomepage";
import DigitalWave from "../../components/Homepage/DigitalWave";
import Footer from "../../components/Footer";
import Banner from "../../components/About/Banner";
import Content from "../../components/About/Content";
import TogleWa from "../../components/TogleWa/TogleWa";

export default function About() {
  return (
    <div className="about d-flex flex-column center w-100 justify-content-center text-center">
      <TogleWa />
      <Header />
      <Banner />
      <Content />
      <DigitalWave />
      <Footer />
    </div>
  );
}
