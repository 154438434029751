import { React, useEffect } from "react";
import "../../styles/Article.css";
import Header from "../../components/Header";
import ArticleList from "../../components/Articles/ArticleList";
import DigitalWave from "../../components/Homepage/DigitalWave";
import Footer from "../../components/Footer";
import TogleWa from "../../components/TogleWa/TogleWa";

export default function Articles() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="articles d-flex flex-column center w-100 justify-content-center text-center articles">
      <TogleWa/>
      <Header />
      <ArticleList />
      <DigitalWave />
      <Footer />
    </div>
  );
}
