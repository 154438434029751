import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import cranium from "../assets/images/cranium.png";
import cranium_y from "../assets/images/cranium_y.png";
import menu_x from "../assets/images/menu_x.png";
import menu_y from "../assets/images/menu_y.png";
import { Drawer, Box, List, ListItem, ListItemButton } from "@mui/material";
import close from "../assets/images/X.png";
// import fb from "../assets/images/fb.png";
import lk from "../assets/images/lk.png";
import ig from "../assets/images/ig.png";

export default function Header() {
  const [scrolled] = useState(true);
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <img
        src={close}
        alt=""
        className="close-header pointer"
        onClick={toggleDrawer(anchor, false)}
      />
      <List>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <p
              className="DMSans-700 c-00 s-2431 header-list"
              onClick={() => (window.location.href = "/about")}
            >
              About
            </p>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#articles"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Articles
            </NavHashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#clients"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Clients
            </NavHashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#career"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Career
            </NavHashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#officeLocation"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Office
            </NavHashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#softwareUtilize"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Product
            </NavHashLink>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding className="header-list">
          <ListItemButton>
            <NavHashLink
              to="/#services"
              className="DMSans-700 c-00 s-2431 header-list"
            >
              Services
            </NavHashLink>
          </ListItemButton>
        </ListItem>
      </List>
      <div className="d-flex justify-content-end header-media">
        <NavHashLink
          to="https://www.instagram.com/craniumindonesia"
          target="_blank"
        >
          <img src={ig} alt="" className="pointer" />
        </NavHashLink>
        <NavHashLink
          to="https://www.linkedin.com/company/cranium-indonesia/"
          target="_blank"
        >
          <img src={lk} alt="" className="pointer" />
        </NavHashLink>
        {/* <img src={fb} alt="" className="pointer" /> */}
      </div>
    </Box>
  );

  return (
    <div className={`w-100 header ${scrolled ? "shd" : ""}`}>
      <div className="d-flex justify-content-between align-items-center">
        <img
          className="logo pointer"
          src={scrolled ? cranium_y : cranium}
          alt=""
          onClick={() => (window.location.href = "/")}
        />
        <div className="d-flex menu align-items-center">
          <p
            className={`${
              scrolled ? "c-89" : "c-ff"
            } DMSans-700 s-2026 pointer`}
          >
            Menu
          </p>
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <img
                className="pointer"
                src={scrolled ? menu_y : menu_x}
                alt=""
                onClick={toggleDrawer(anchor, true)}
              />
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
