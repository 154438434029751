import React, { useEffect } from "react";
import "../../styles/Homepage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "../../components/HeaderHomepage";
import Footer from "../../components/Footer";
import OurJourney from "../../components/Homepage/OurJourney";
import OurClient from "../../components/Homepage/OurClients";
import SoftwareSolution from "../../components/Homepage/SoftwareSolution";
import Articles from "../../components/Homepage/Articles";
import Career from "../../components/Homepage/Career";
import OfficeLocation from "../../components/Homepage/OfficeLocation";
import DigitalWave from "../../components/Homepage/DigitalWave";
import TogleWa from "../../components/TogleWa/TogleWa";

export default function Homepage() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className="homepage d-flex flex-column justify-content-center text-center width-100">
      <TogleWa/>
      <Header />
      <OurJourney />
      <OurClient />
      <SoftwareSolution />
      <DigitalWave />
      <Articles />
      <Career />
      <OfficeLocation />
      <Footer />
    </div>
  );
}
