import { React, useState, useEffect } from "react";
import ReactPlayer from "react-player";
import ErpVideo from "../../assets/videos/product-erp.mov";
import Aos from "aos";
import "aos/dist/aos.css";
import LoadingComp from "../Loading/LoadingComp";
import { getProduct } from "../../services/api";

export default function Content() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);

  async function getProductData() {
    try {
      const responseProduct = await getProduct().then(
        (result) => result.data.data
      );
      setProduct(responseProduct);
      setTimeout(() => {
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getProductData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div className="client-strategy d-flex flex-wrap justify-content-center width-100">
          <div className="container-inner">
            <div className="row" data-aos="fade-up" data-aos-duration="1500">
              {product.map((productView, index) => {
                return (
                  index === 0 && (
                    <div
                      className="col-lg-12 strategy-objectif mb-50"
                      key={index}
                    >
                      <h6 className="Arlon c-89 s-4052 mb-50">DESCRIPTION</h6>
                      <div
                        className="width-100"
                        dangerouslySetInnerHTML={{
                          __html: productView.description,
                        }}
                      ></div>
                    </div>
                  )
                );
              })}
            </div>
            {product.map((productView, index) => {
              return (
                index === 0 && (
                  <div className="row mb-200" key={index}>
                    <div className="col-lg-6">
                      <h6
                        className="Arlon c-89 s-4052 mb-50 pl-52"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        CHALLENGES
                      </h6>
                      <div
                        className="d-flex flex-column col-12 mb-54"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <div
                          className="width-100"
                          dangerouslySetInnerHTML={{
                            __html: productView.challenge,
                          }}
                        ></div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h6
                        className="Arlon c-89 s-4052 mb-50 pl-52"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        SOLUTIONS
                      </h6>
                      <div
                        className="d-flex flex-column col-12 mb-54"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <div
                          className="width-100"
                          dangerouslySetInnerHTML={{
                            __html: productView.solution,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <div
            className="row product-video mb-200 width-100"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <div className="col-lg-12 d-flex justify-content-center align-items-center">
              <ReactPlayer
                playing={true}
                loop={true}
                url={ErpVideo}
                width={1520}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
