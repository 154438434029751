import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import link from "../../assets/case/icon.svg";
import scBgLeft from "../../assets/case/sc-bg-left.png";
import scBgRight from "../../assets/case/sc-bg-right.png";
import { getClientId } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function ClientDetail() {
  const { id } = useParams();
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getClientIdData() {
    try {
      const responseDetail = await getClientId(id).then(
        (result) => result.data.data
      );
      setClientData(responseDetail);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    Aos.init();
    getClientIdData();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div className="client-case d-flex flex-wrap justify-content-center">
          <div className="container-inner">
            <div
              className="row client-identify mb-5"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="col-lg-3 d-flex flex-column ">
                <p className="DMSans-700 c-00 s-2431 mb-10">Client</p>
                <p className="DMSans-400 c-00 s-2026">{clientData.name}</p>
              </div>
              <div className="col-lg-3 d-flex flex-column border-box">
                <p className="DMSans-700 c-00 s-2431 mb-10">Brand</p>
                <img
                  src={clientData.logo}
                  className="brand img-contain img-fluid"
                  alt="brand-img"
                />
              </div>
              <div className="col-lg-3 d-flex flex-column border-box">
                <p className="DMSans-700 c-00 s-2431 mb-10">Our Role</p>
                <p className="DMSans-400 c-00 s-2026">{clientData.roles}</p>
              </div>
              <div className="col-lg-3 d-flex flex-column border-box">
                <p className="DMSans-700 c-00 s-2431 mb-10">Project Link</p>
                <div className="d-flex">
                  <img src={link} alt="img-link" />
                  &nbsp;
                  <p className="DMSans-400 c-00 s-2026">{clientData.link}</p>
                </div>
              </div>
            </div>
            <div
              className="client-description row mb-200"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <p className="col-lg-12 text-start DMSans-700 s-2431 mb-30">
                About Brand
              </p>
              <div
                className="about-brand width-100"
                dangerouslySetInnerHTML={{ __html: clientData.about }}
              ></div>
            </div>
          </div>
          <div className="client-case-project bg-f8 py-50 d-flex justify-content-center width-100 mb-200">
            <img
              src={scBgLeft}
              className="case-bg-left img-fluid img-contain"
              alt="img-case-bg"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            />
            <img
              src={scBgRight}
              className="case-bg-right img-fluid img-contain"
              alt="img-case-bg"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            />

            <div className="container-inner relative z-5">
              <div className="row">
                {clientData.item.map((clientView, i) => (
                  <div
                    className="col-lg-6 mb-4"
                    data-aos="zoom-in-right"
                    data-aos-duration="1500"
                    key={i}
                  >
                    <img
                      src={clientView}
                      className="img-fluid"
                      alt="case-img"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
