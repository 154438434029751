import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { getClientId } from "../../services/api";
export default function Strategy() {
  const { id } = useParams();
  const [clientDetail, setClientDetail] = useState([]);

  async function getClientIdData() {
    const responseDetail = await getClientId(id).then(
      (result) => result.data.data
    );
    setClientDetail(responseDetail);
  }
  useEffect(() => {
    Aos.init();
    getClientIdData();
  }, []);
  return (
    <div className="client-strategy d-flex flex-wrap justify-content-center width-100">
      <div className="container-inner">
        <div className="row" data-aos="fade-up" data-aos-duration="1500">
          <div className="col-lg-12 strategy-objectif mb-50 ">
            <h6 className="Arlon c-89 s-4052 mb-50">OBJECTIVES</h6>
            <div
              className="objective"
              dangerouslySetInnerHTML={{ __html: clientDetail.objective }}
            ></div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h6
              className="Arlon c-89 s-4052 mb-50 pl-52"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              CHALLENGES
            </h6>
            <div
              className="challange width-100"
              data-aos="fade-up-right"
              data-aos-duration="1500"
              dangerouslySetInnerHTML={{ __html: clientDetail.challenge }}
            ></div>
            {/* {list.map((item, i) => (
              <div className="d-flex flex-column col-12 mb-54" data-aos="fade-up" data-aos-duration="1500">
                <li className="DMSans-700 s-2431 c-00 col-12 border-box pl-52 mb-30">
                  Lorem Ipsum
                </li>
                <p className="DMSans-400 s-2026 c-00 col-12 border-box pl-52">
                  Lorem ipsum dolor sit amet consectetur. Tristique duis
                  ullamcorper bibendum leo commodo amet pharetra amet. Feugiat
                  faucibus interdum ipsum donec. Quis dolor lacus magna vitae
                  tincidunt egestas lectus pretium urna.
                </p>
              </div>
            ))} */}
          </div>
          <div className="col-lg-6">
            <h6
              className="Arlon c-89 s-4052 mb-50 pl-52"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              SOLUTIONS
            </h6>
            <div
              className="solution width-100"
              data-aos="fade-up-left"
              data-aos-duration="1500"
              dangerouslySetInnerHTML={{ __html: clientDetail.solution }}
            ></div>
            {/* {list.map((item, i) => (
              <div className="d-flex flex-column col-12 mb-54" data-aos="fade-up" data-aos-duration="1500">
                <li className="DMSans-700 s-2431 c-00 col-12 border-box pl-52 mb-30">
                  Lorem Ipsum
                </li>
                <p className="DMSans-400 s-2026 c-00 col-12 border-box pl-52">
                  Lorem ipsum dolor sit amet consectetur. Tristique duis
                  ullamcorper bibendum leo commodo amet pharetra amet. Feugiat
                  faucibus interdum ipsum donec. Quis dolor lacus magna vitae
                  tincidunt egestas lectus pretium urna.
                </p>
              </div>
            ))} */}
          </div>
        </div>
      </div>
    </div>
  );
}
