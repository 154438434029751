import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import art1 from "../../assets/articles/art1.png";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TopArticle from "../Articles/TopArticle";
import LoadingComp from "../Loading/LoadingComp";
import Aos from "aos";
import "aos/dist/aos.css";
import { getArticlesPagination } from "../../services/api";

export default function ArticleList() {
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  function handleChange(e, value) {
    setCurrentPage(value);
  }

  useEffect(() => {
    Aos.init();
    async function getArticle() {
      try {
        const responseArticles = await getArticlesPagination(currentPage).then(
          (result) => result.data.data
        );
        setArticles(responseArticles);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getArticle();
  }, [currentPage]);

  useEffect(() => {
    async function setPage() {
      try {
        setLoading(true);
        const resStart = await getArticlesPagination(currentPage).then(
          (result) => result.data.data
        );
        setArticles(resStart);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    setPage();
  }, [currentPage]);

  return (
    <div className="d-flex justify-content-center my-200">
      <div className="container-inner">
        <div className="width-100">
          <h3 className="Arlon c-00 s-4862 text-start">ARTICLES</h3>
          <TopArticle />
          <p className="DMSans-700 s-3242 c-00 mt-100 mb-50 text-start">
            All Articles
          </p>
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#b89848" />
          ) : (
            <div className="width-100">
              <div className="row list-article">
                {articles.content.map((item, i) => (
                  <div
                    key={item.id}
                    className="col-xxl-3 col-xl-4 col-lg-6 col-sm-6 article-inner"
                    data-aos="zoom-in-up"
                    data-aos-duration="1500"
                  >
                    <div className="article-item shd-40 d-flex flex-row br-10 flex-sm-column">
                      <div className="box-img-article relative width-100">
                        <img
                          src={art1}
                          className="img-article img-fluid img-cover width-100"
                          alt="img-article"
                        />
                        <p className="relative z-2 c-00 s-1823 bg-255 br-5 p-10 DMSans-700">
                          News
                        </p>
                      </div>
                      <div className="box-text-article d-flex flex-column justify-content-between p-20 border-box text-start width-100">
                        <div className="d-flex flex-column width-100">
                          <p className="c-52 s-1621 DMSans-400">{item.date}</p>
                          <p className="DMSans-700 s-2026 c-00 mt-30 mb-20">
                            {item.title.substring(0, 35)}...
                          </p>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.summary.substring(0, 120),
                            }}
                          ></div>
                        </div>
                        <Link
                          to={item.link}
                          target="_blank"
                          className="c-89 DMSans-700 s-1823 m_auto no"
                        >
                          Find out more {">"}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="d-flex justify-content-end col-12">
                <Stack spacing={2}>
                  <Pagination
                    count={articles.totalPages}
                    page={currentPage}
                    onChange={handleChange}
                    classes={{
                      ul: {
                        "& .MuiPaginationItem-root": {
                          color: "#B89848",
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
