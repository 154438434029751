import {React} from "react";
import RiseLoader from "react-spinners/RiseLoader";
export default function LoadingComp(props) {
  return (
    <div className="relative d-flex justify-content-center align-items-center my-50 width-100">
      <RiseLoader
        color={props.color}
        loading={props.statusLoading}
        size={16}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
