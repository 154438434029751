import axios from 'axios';

const baseURL = process.env.REACT_APP_URI

export const getClient = async () => {
    return await axios.get(`${baseURL}/api/client`)
};
export const getClientSlider = async () => {
  return await axios.get(`${baseURL}/api/client/slider`)
};

export const getClientId = async (id) => {
    return await axios.get(`${baseURL}/api/client/${id}`)
};
export const getHomepageBanner = async (id = 2) => {
  return await axios.get(`${baseURL}/api/banner/${id}`)
};

export const getJourney = async (year) => {
    return await axios.get(`${baseURL}/api/journeys/${year}`)
};

export const getLocationType = async () => {
    return await axios.get(`${baseURL}/api/locations/type`)
};

export const getLocation = async (type) => {
    return await axios.get(`${baseURL}/api/locations/${type}`)
};

export const getTestimonies = async () => {
    return await axios.get(`${baseURL}/api/testimonies`)
};
export const getSoftwareSolution = async () => {
  return await axios.get(`${baseURL}/api/services`)
};
export const getSoftwareSolutionId = async (id) => {
  return await axios.get(`${baseURL}/api/services/${id}`)
};
export const getTechStack = async () => {
  return await axios.get(`${baseURL}/api/tech-stack`)
};

export const getCareer = async () => {
    return await axios.get(`${baseURL}/api/career`)
};

export const getCareerByDivision = async (id) => {
    return await axios.get(`${baseURL}/api/career/division/${id}`)
};

export const getDivision = async () => {
    return await axios.get(`${baseURL}/api/career/division`)
};

export const getArticles = async (page = 1) => {
    return await axios.get(`${baseURL}/api/articles?page=${page}`);
};
export const getArticlesPagination = async (page) => {
  return await axios.get(`${baseURL}/api/articles?page=${page}`);
};

export const getAbout = async () => {
  return await axios.get(`${baseURL}/api/about`)
};
export const getAboutBanner = async () => {
  return await axios.get(`${baseURL}/api/about/banner`)
};

export const getAboutLeadership = async () => {
  return await axios.get(`${baseURL}/api/about/leader`)
};

export const getProduct = async () => {
  return await axios.get(`${baseURL}/api/product`)
};