import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import ussBgTop from "../../assets/homepage/uss-bg-top.png";
import ussBgBottom from "../../assets/homepage/uss-bg-bottom.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSoftwareSolution, getTechStack } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function SoftwareSolution() {
  const navigate = useNavigate();
  const [softwareSolution, setSoftwareSolution] = useState([]);
  const [techStack, setTechStack] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getSoftwareSolutionData() {
    try {
      const responseData = await getSoftwareSolution().then(
        (result) => result.data.data
      );
      setSoftwareSolution(responseData);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getTechStackData() {
    try {
      const responseStack = await getTechStack().then(
        (result) => result.data.data
      );
      setTechStack(responseStack);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();

    getSoftwareSolutionData();
    getTechStackData();
  }, []);
  var slideSoftwareSolution = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
        },
      },
    ],
  };

  var slideTechnologyStack = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
  };

  const application_list = [];

  const initialState = application_list
    .filter((link) => link.sublinks !== undefined)
    .reduce((a, v) => ({ ...a, [v.id]: false }), {});

  const [collapseState, setCollapseState] = useState(initialState);

  const handleClick = (navId) =>
    setCollapseState((prev) => {
      return { ...prev, [navId]: !prev[navId] };
    });
  const handleErp = () => {
    navigate("/erp");
  };

  return (
    <div className="software-solution d-flex flex-wrap justify-content-center width-100">
      <div
        className="software-option d-flex flex-column align-items-center justify-content-center mt-50 mb-150"
        id="services"
      >
        <h3
          className="Arlon uppercase mb-30"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <span className="c-89">BUILD </span> YOUR DIGITAL SOLUTION SOFTWARE
        </h3>
        <p
          className="DMSans-400 c-00 s-2431 mb-50"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          Does your business need a top-tier development team to create, test,
          implement, and optimize software or business system?
        </p>
        {loading ? (
          <LoadingComp statusLoading={setLoading} color="#b89848" />
        ) : (
          <div className="width-100">
            <Slider {...slideSoftwareSolution}>
              {softwareSolution.map((solutionView) => (
                <div className="card-outer" key={solutionView.id}>
                  <div
                    className="card d-flex flex-column justify-content-between"
                    data-aos="zoom-in"
                    data-aos-duration="1500"
                    onClick={() => handleClick(solutionView.id)}
                  >
                    <div className="box-ss">
                      <div className="ss-title">
                        <p className="c-00 s-2431 DMSans-700">
                          {solutionView.name}
                        </p>
                      </div>
                      <div className="ss-image d-flex justify-content-center">
                        <img
                          src={solutionView.image}
                          className="img-fluid img-contain"
                          alt="img-software-solution"
                        />
                      </div>
                    </div>
                    <div className="ss-desc-mobile text-start d-none">
                      <Link
                        to={`/software-solution/${solutionView.id}`}
                        className="decotarion-none c-00"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: solutionView.shortDescription,
                          }}
                        ></div>
                      </Link>
                    </div>
                  </div>
                  <Collapse in={collapseState[solutionView.id]}>
                    <div className="ss-desc-desktop mx-3 my-3">
                      <Link
                        to={`/software-solution/${solutionView.id}`}
                        className="decotarion-none c-00"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: solutionView.shortDescription,
                          }}
                        ></div>
                      </Link>
                    </div>
                  </Collapse>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>

      <div
        className="software-utilize d-flex align-items-center width-100"
        id="softwareUtilize"
      >
        <div
          className="uss-bg-top"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          <img
            src={ussBgTop}
            className="img-contain img-fluid"
            alt="img-uss-top"
          />
        </div>
        <div className="width-100">
          <h3
            className="Arlon uppercase s-6483 mb-30"
            data-aos="zoom-in"
            data-aos-duration="2500"
          >
            UTILIZE OUR <span className="c-89"> READY TO USE SOFTWARE </span>{" "}
            SOLUTION
          </h3>
          <button
            className="DMSans-700 s-2431 c-89 br-30 view bg-ff"
            data-aos="zoom-in-left"
            data-aos-duration="1500"
            onClick={handleErp}
          >
            View more
          </button>
        </div>
        <div
          className="uss-bg-bottom"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <img
            src={ussBgBottom}
            className="img-contain img-fluid"
            alt="img-uss-bottom"
          />
        </div>
      </div>

      <div className="technology-stack width-100 bg-f8 py-200">
        <div className="width-100">
          <h6
            className="c-00 Arlon s-3647 mb-50"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            TECHNOLOGY STACK
          </h6>
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#b89848" />
          ) : (
            <Slider {...slideTechnologyStack}>
              {techStack.map((stackView) => (
                <div
                  className="d-flex justify-content-center"
                  key={stackView.id}
                  data-aos="fade-down"
                  data-aos-duration="1500"
                >
                  <img
                    src={stackView.icon}
                    className="img-fluid img-contain"
                    alt="technology-stack-img"
                  />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  );
}
