import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { getSoftwareSolutionId } from "../../services/api";
import LoadingComp from "../../components/Loading/LoadingComp";
import Aos from "aos";
import "aos/dist/aos.css";

export default function SoftwareSolutionDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [softwareSolution, setSoftwareSolution] = useState([]);

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
    async function getSoftwareSolutionData() {
      try {
        const responseSS = await getSoftwareSolutionId(id).then(
          (result) => result.data.data
        );
        setSoftwareSolution(responseSS);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getSoftwareSolutionData();
  }, []);

  return (
    <div className="box-ss-detail d-flex justify-content-center">
      <div className="container-inner">
        <div className="ss-detail relative mt-50 mb-100 width-100">
          <div className="nav-back z-2 width-100 p-3 mb-30">
            <NavHashLink to="/#services" className="no c-00">
              <h6 className="pointer">
                <span className="me-2">
                  <svg
                    fill="#b89848"
                    height="30px"
                    width="30px"
                    version="1.1"
                    id="Layer_1"
                    // xmlns="http://www.w3.org/2000/svg"
                    // xmlns:xlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 476.213 476.213"
                    // xml:space="preserve"
                  >
                    <polygon
                      points="476.213,223.107 57.427,223.107 151.82,128.713 130.607,107.5 0,238.106 130.607,368.714 151.82,347.5 
	57.427,253.107 476.213,253.107 "
                    />
                  </svg>
                </span>
                Back
              </h6>
            </NavHashLink>
          </div>
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#b89848" />
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: softwareSolution.description }}
              data-aos="zoom-in-up"
              data-aos-duration="1500"
            ></div>
          )}
        </div>
      </div>
    </div>
  );
}
