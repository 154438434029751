import { React, useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import banner from "../../assets/about/banner_bg.png";
import LoadingComp from "../Loading/LoadingComp";
import { getAbout, getAboutBanner } from "../../services/api";
import ScrollDown from "../ScrollDown";

export default function Banner() {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState([]);
  const [bannerDesc, setBannerDesc] = useState([]);
  async function getAboutData() {
    try {
      const responseAbout = await getAbout().then((result) => result.data.data);
      setAbout(responseAbout);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getBannerData() {
    try {
      const responseBanner = await getAboutBanner().then(
        (result) => result.data.data
      );
      setBannerDesc(responseBanner);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getAboutData();
    getBannerData();
  }, []);
  return (
    <>
      <div>
        {about.map((aboutView) => (
          <div
            className="banner-about d-flex align-items-center mb-200 width-100"
            key={aboutView.id}
          >
            <img
              src={banner}
              className="banner-img img-cover width-100"
              alt="img-banner"
              data-aos="zoom-in"
              data-aos-duration="1500"
            />

            <div className="banner-content width-100 d-flex justify-content-center relative">
              {loading ? (
                <LoadingComp statusLoading={setLoading} color="#fff" />
              ) : (
                <>
                  <ScrollDown />
                  <div className="box-inner">
                    <div className="box-content-inner d-flex align-items-center width-100">
                      <div className="about-text">
                        <div
                          className="content-text text-start width-100"
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <h1 className="s-6483 Arlon c-ff mb-30">
                            {aboutView.title}
                          </h1>
                          {bannerDesc.map((bannerView, i) => {
                            return (
                              i === 0 && (
                                <div
                                  className="banner-desc"
                                  dangerouslySetInnerHTML={{
                                    __html: bannerView.description,
                                  }}
                                  key={i}
                                ></div>
                              )
                            );
                          })}
                        </div>
                      </div>
                      <div className="about-img relative">
                        {bannerDesc.map((bannerView, i) => {
                          return (
                            i === 0 && (
                              <div
                                className="content-img d-flex justify-content-between"
                                data-aos="fade-up-left"
                                data-aos-duration="1500"
                                key={i}
                              >
                                <div className="box-img-left">
                                  <img
                                    src={bannerView.image[0]}
                                    className="banner-left img-cover width-100 img-fluid"
                                    alt="img-content"
                                  />
                                </div>
                                <div className="box-img-right">
                                  <img
                                    src={bannerView.image[1]}
                                    alt=""
                                    className="banner-right img-cover"
                                  />
                                </div>
                              </div>
                            )
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
