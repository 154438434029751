import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import bg_journey from "../../assets/homepage/bg_journey.png";
import icon_left from "../../assets/images/left.png";
import icon_right from "../../assets/images/right.png";
import mainVideo from "../../assets/videos/main-video.mov";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingComp from "../Loading/LoadingComp";
import ScrollDown from "../ScrollDown";
import { getHomepageBanner, getJourney } from "../../services/api";

export default function OurJourney() {
  const [banner, setBanner] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [content, setContent] = useState([]);
  const [year, setYear] = useState();
  const [loading, setLoading] = useState(true);

  async function getBannerData() {
    try {
      const getBanner = await getHomepageBanner().then(
        (result) => result.data.data
      );
      setBanner(getBanner);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getData() {
    try {
      const getYear = await getJourney().then(
        (result) => result.data.data.year
      );
      setYearList(getYear);
      setYear(getYear[0]);

      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getBannerData();
    getData();
  }, []);

  useEffect(() => {
    async function updateContent() {
      try {
        const getContent = await getJourney(year).then(
          (result) => result.data.data.content
        );
        setContent(getContent);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    updateContent();
  }, [year]);

  var sliderJourney = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    // prevArrow: (
    //   <button>
    //     <svg
    //       width="10"
    //       height="16"
    //       viewBox="0 0 10 16"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M2.38469 15.1337L9.51844 7.99996L2.38469 0.866211L0.617188 2.63371L5.98344 7.99996L0.617188 13.3662L2.38469 15.1337Z"
    //         fill="#B89848"
    //       />
    //     </svg>
    //   </button>
    // ),
    // nextArrow: (
    //   <button>
    //     <svg
    //       width="10"
    //       height="16"
    //       viewBox="0 0 10 16"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M2.38469 15.1337L9.51844 7.99996L2.38469 0.866211L0.617188 2.63371L5.98344 7.99996L0.617188 13.3662L2.38469 15.1337Z"
    //         fill="#B89848"
    //       />
    //     </svg>
    //   </button>
    // ),
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        },
      },
    ],
  };
  return (
    <div className="col-12 ">
      <div className="box-main-banner d-flex align-items-center relative width-100">
        <ScrollDown />
        <div className="main-video width-100">
          <ReactPlayer
            playing={true}
            loop={true}
            url={mainVideo}
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          />
        </div>
        {loading ? (
          <div className="min-h-100-vh d-flex align-items-center width-100">
            <LoadingComp statusLoading={setLoading} color="#fff" />
          </div>
        ) : (
          <div className="main-text relative z-10 width-100">
            <div
              className=""
              dangerouslySetInnerHTML={{
                __html: banner.text,
              }}
              data-aos="zoom-in"
              data-aos-duration="2500"
            ></div>
          </div>
        )}
      </div>

      <div className="box-our-journey relative w-100 d-flex justify-content-center">
        <img
          src={bg_journey}
          className="bg-journey img-fluid img-cover width-100"
          alt="img-bg-journey"
        />
        <div className="container-inner d-flex justify-content-between align-items-center wd-content">
          {loading ? (
            <div className="d-flex justify-content-center width-100">
              <div className="d-lg-flex d-none width-100">
                <LoadingComp color="#fff" statusLoading={loading} />
              </div>
              <div className="d-lg-none width-100">
                <LoadingComp color="#b89848" statusLoading={loading} />
              </div>
            </div>
          ) : (
            <div className="box-journey d-flex justify-content-between width-100">
              <div className="journey-desc d-flex flex-column">
                <div className="relative d-flex justify-content-center width-100">
                  <h3
                    className="c-ff Arlon"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    OUR JOURNEY
                  </h3>
                </div>
                <div className="width-100">
                  <Slider {...sliderJourney}>
                    {content.map((item) => {
                      return (
                        // item.id < 2 && (
                        <div className="card-inner width-100" key={item.id}>
                          <div
                            className="card d-flex flex-column text-start"
                            data-aos="zoom-in"
                            data-aos-duration="1500"
                          >
                            <p className="DMSans-500 c-00 s-1823 mb-30">
                              {item.eventDate}
                            </p>
                            <h6 className="c-89 DMSans-700 s-2431 mb-20">
                              {item.title}
                            </h6>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                          </div>
                        </div>
                        // )
                      );
                    })}
                  </Slider>
                </div>
              </div>
              <div className="journey-paginate d-flex">
                <div className="paginate-list d-flex flex-column justify-content-center">
                  <div className="d-flex flex-column times align-items-center">
                    {yearList.indexOf(year) - 1 >= 0 && (
                      <img
                        src={icon_left}
                        alt=""
                        className="pointer"
                        onClick={() => {
                          console.log(
                            "current Top",
                            yearList.indexOf(year) - 1
                          );
                          setYear(yearList[yearList.indexOf(year) - 1]);
                        }}
                      />
                    )}
                    {yearList.map((item, i) => (
                      <div className="relative z-2" key={i}>
                        <button
                          onClick={() => {
                            console.log("current button", item);
                            setYear(item);
                          }}
                          className={`btn btn-primary btn-year-list DMSans-700 s-1823 c-ff d-flex justify-content-center align-items-center ${
                            item === year && "active"
                          }`}
                        >
                          {item}
                        </button>
                      </div>
                    ))}
                    {yearList.indexOf(year) + 1 <= yearList.length && (
                      <img
                        src={icon_right}
                        alt=""
                        className="pointer"
                        onClick={() => {
                          console.log(
                            "current Bottom",
                            yearList.indexOf(year) - 1
                          );
                          setYear(yearList[yearList.indexOf(year) + 1]);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
