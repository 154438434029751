import {React, useEffect} from 'react'

import "../../styles/CaseStudy.css"
import HeaderHomepage from '../../components/HeaderHomepage'
import Banner from '../../components/CaseStudy/Banner'
import ClientDetail from '../../components/CaseStudy/ClientDetail'
import Strategy from '../../components/CaseStudy/Strategy'
import DigitalWave from '../../components/Homepage/DigitalWave'
import Footer from '../../components/Footer'
import TogleWa from '../../components/TogleWa/TogleWa'

export default function CaseStudy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  },[])

  return (
    <div className='box-case-study case width-100'>
      <TogleWa/>
        <HeaderHomepage/>
        <Banner />
        <ClientDetail />
        <Strategy/>
        <DigitalWave />
        <Footer/>
    </div>
  )
}
