import React from "react";

export default function ScrollDown() {
  return (
    <>
      <div className="scroll-downs">
        <div className="mousey">
          <div className="scroller"></div>
          <div className="scroller-arrow"></div>
        </div>
        <p className="c-ff">Scroll to explore more</p>
      </div>
    </>
  );
}
